<template>
  <div class="valorador">
    <div>
      <h2 class="left">Empresas</h2>
      <div class="box left" style="padding:20px">
        <h3>Crear empresa</h3>
        <vs-row vs-type="flex" vs-align="flex-end" vs-justify="space-between" vs-w="12">
            <vs-col vs-type="flex" vs-justify="left" vs-align="center" vs-w="6">
              <vs-input type="text" v-model="newClient.name" class="inputx" placeholder="Nombre"/>
              &nbsp;&nbsp;&nbsp;
              <vs-input type="text" v-model="newClient.uri" placeholder="Ruta interna" icon="trash" icon-pack="fas fa-slash flip-slash"/>
              &nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;
              <vs-button @click="create(newClient)">Crear</vs-button>
            </vs-col>

        </vs-row>
      </div>
    </div>
    <br><br>
    <table-render :rows.sync="rows" :schema="schema" :actions="actions" v-if="ready" :link="open" :noAutoResize="true" />
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapMutations } from 'vuex'
import { onSnapshot, collection, db, setDoc, addDoc, doc, orderBy, query, updateDoc, getDoc, getDocs, deleteDoc } from '../firebase'
import Caja from './Caja.vue'
import TableRender from './Rage/TableRender.vue'
export default {
  components: { Caja, TableRender },
  name: 'Admin',
  data() {
    return {
      ready: false,
      clients: [],
      actions: [],
      rows: [],
      schema: [],
      params: [],
      newClient: {
        googlePreference: 50,
        active: true,
        backgroundColor: '#ffffff',
        textColor: '#000000',
        googleUrl: 'https://google.com',
        facebookUrl: 'https://facebook.com',
        logo: '',
        logoWidth: '',
        title: '¡Hola!',
        namedTitle: '¡Hola, %n!',
        description: '¿Cómo calificas nuestro servicio?',
        thanks: '¡Gracias!',
        lowRatingMessage: '¡Por favor dinos como podríamos mejorar!',
      },
    }
  },
  computed: {
    ...mapGetters(['user']),
  },
  async mounted() {

    this.schema = [
      {label: 'Nombre', visible: true, key: 'name', width: 300},
      {label: 'URI', visible: true, key: 'uri', width: 300},
      {label: 'Ops', visible: true, key: 'actions', width: 200},
      // {label: 'watch', visible: true, key: 'watch', width: 100},
    ];

    const paramsRef = doc(collection(db, 'config'), 'params');
    onSnapshot(paramsRef, async (params) => {
      //do all stuff after params are fetched, because the table needs to know what buttons to show
      if (params.data()) this.params = params.data();
      this.ready = false;


      this.actions = [
        {action: id => this.switchActive({id}), icon: 'power-off', switchKey:'active'},
        {...this.params.removeClients?{action: id => this.del({id}), icon: 'trash', color:'darkred'}:{}},
      ];

      //traer empresas de firebase
      const q = query(collection(db, "clients"), orderBy('created', 'desc'));
      onSnapshot(q, snapshot=>{
        const clients = []
        this.ready = false;
        this.rows = [];
        snapshot.forEach(doc => {
          clients.push({id: doc.id, ...doc.data()})
        });
        this.$nextTick(() => {
          this.parseRows(clients);
        });
        this.ready = true;
      });

    });
  },
  methods: {
    switchActive(clientId) {
      const client = this.rows.find(client => client.id === clientId.id);
      client.active = !client.active;
      delete client.actions;
      updateDoc(doc(db, "clients", client.id), client);
    },
    parseRows(clients) {
      const rows = []
      for (const client of clients) {
        rows.push({...client})
      }
      this.rows = rows;
    },
    open(client) {
      console.log(`abro`, client);
      this.$router.push('/empresas/' + client.id);
    },
    go(uri) {
      location='/'+uri
    },
    del(client) {
      console.log(`borreo`, client);
      if (!confirm('¿borrar?')) return
      console.log(`reborro`,);
      // this.clients.splice(this.clients.indexOf(client), 1)
      deleteDoc(doc(db, "clients", client.id))
    },
    create(client) {
      addDoc(collection(db, "clients"), {...client, created:new Date()})
      this.newClient.name = '';
      this.newClient.email = '';
      this.newClient.uri = '';
    }
  },
  watch: {
    rating: function (val) {
      if (val < 4) {
        this.showFeedback = true
      } else {
        location='https://search.google.com/local/writereview?placeid=ChIJ9VH-EofFYpYR0f7LytCPG7U'
      }
      this.$emit('rating', val)
    }
  }
}
</script>
