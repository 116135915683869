<template>
  <div class="acceso">
    <div class="titulo">
      <h1>Acceder a <a href="https://enti.cl">ENTi</a></h1>
      <!-- <h3>Ingresa tus datos de acceso. Si aún no eres cliente, <a href="https://enti.cl">solicita tu demo aquí!</a></h3> -->
    </div>
    <caja v-if="denegado">
      <h1>Acceso denegado</h1>
      <div style="text-align:center">
        <vs-button style="float:none; width:130px;margin:auto" @click="logout" icon=".." icon-pack="fab fa-google">
          Cerrar sesión</vs-button>
      </div>
    </caja>
    <caja v-if="user.notLoggedIn">
      <div style="text-align:center">
        <vs-button style="display:inline-block;float:none" @click="login" icon=".." icon-pack="fab fa-google">
          Entrar con Google</vs-button>
      </div>
      <br><br>
    </caja>
  </div>
</template>

<style lang="scss">
.titulo {
  width: 900px;
  margin: auto;
  color: white;
  margin-bottom: 40px;

  h3 {
    margin-top: 20px;
  }

  a {
    color: white;
    text-decoration: underline;
  }
}
</style>

<script>
/* eslint-disable */
import Empresas from './Empresas.vue';
import Clientes from './Clientes.vue';
import { mapGetters, mapMutations } from 'vuex'
import { loginWithGoogle, logout } from '../session'
import Caja from './Caja.vue';
import Logo from './Logo.vue';


export default {
  name: 'Admin',
  components: { Empresas, Clientes, Caja, Logo },
  data() {
    return {
      denegado: false,
      logout
    }
  },
  computed: {
    ...mapGetters(['user']),
  },
  methods: {
    login() {
      loginWithGoogle();
    }
  },
  watch: {
    user(u) {
      if (u.name) {
        console.log(`user`, u);
        if (u.level === 1) this.$router.push('/clientes');
        else if (u.level === 2 || u.level === 5) this.$router.push('/empresas');
        else this.denegado = true;
      }
    }
  }
}
</script>

